.pag-contato{
	.title-1{
		margin-bottom: 30px;
	}
	.midia{
		margin-bottom: 10px;
		line-height: 1.2;
		i.fas{
			color: #323545;
		}
	}
	.form-estilos{
		margin-top: 30px;
	}
	#mapa-contato{
		padding-bottom: 100%;
		border-radius: 10px;
	}

	@include media-breakpoint-only(xl) {
		.title-1{
			text-align: left;
			&:before{
				left: 0;
				transform: translateX(0);
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.form-estilos{
			margin-bottom: 30px;
		}
	}
	@include media-breakpoint-up(sm) {
		.midia{
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			a + a{
				margin-left: 5px;
				padding-left: 5px;
				border-left: 1px solid #333;
			}

			i.fas{
				width: 20px;
				margin-right: 5px;
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.midia{
			text-align: center;
			display: flex;
			flex-direction: column;

			i.fas{
				margin-bottom: 5px;
			}
		}
	}
}