.pag-quem-somos{
	padding-bottom: 0;

	.owl-carousel{
		padding-bottom: 30px;

		.owl-nav{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 80px;

			.owl-prev,
			.owl-next{
				position: absolute;
				bottom: 0;
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}
	//.owl-carousel

	.primeiro-bloco{
		.container{
			border-bottom: 5px solid #eee;
			padding-bottom: 30px;
		}

		.title-1,
		.desc{
			margin-bottom: 20px;
		}

		.lazyimage{
			margin-left: auto;
			margin-right: auto;
			border-radius: 15px;
			overflow: hidden;
		}

		#accordionQuemSomos{
			.item + .item{
				margin-top: 10px;
			}
			.header{
				background-color: #aaa;
				color: #fff;
				font-size: 14px;
				line-height: 1;
				font-family: $trajan-bold;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px;
				flex-wrap: wrap;
				transition: all 0.3s linear;
				cursor: pointer;

				.plus{
					width: 12px;
					height: 12px;
					position: relative;

					&:before,
					&:after{
						content: '';
						position: absolute;
						background-color: #fff;
						z-index: 2;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					&:before{
						width: 12px;
						height: 4px;
					}
					&:after{
						width: 4px;
						height: 12px;
						transition: all 0.3s linear;
					}
				}
			}
			.header[aria-expanded="true"] {
				background-color: $azul;

				.plus{
					&:after{
						height: 0;
					}
				}
			}
			.content{
				border: 1px solid #ddd;
				color: #878787;
				padding: 10px;
				font-size: 13px;
				line-height: 1.3;
			}
		}
		//#accordionQuemSomos
		@include media-breakpoint-up(lg) {
			.title-1{
				text-align: left;
				&:before{
					left: 0;
					transform: translateX(0);
				}
			}
		}
		@include media-breakpoint-down(md) {
			#accordionQuemSomos{
				margin-bottom: 30px;
			}
		}
	}
	//.primeiro-bloco

	.segundo-bloco{
		padding: 50px 0;
		.title-1{
			margin-bottom: 100px;
		}

		#carouselCorpo{
			.controles{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 20px;

				.prev-carousel,
				.next-carousel{
					margin: 0 10px;
				}
			}
		}
	}
	//.segundo-bloco

	.terceiro-bloco{
		background-color: #eee;
		padding: 50px 0;

		.title-2{
			font-size: 32px;
			color: $azul;
			text-align: center;
			line-height: 1;
			font-family: $trajan-regular;
			margin-bottom: 30px;
		}
	}
	//.terceiro-bloco

	.quarto-bloco{
		padding: 50px 0;
		.title-1{
			margin-bottom: 40px;
		}
		.desc{
			text-align: center;
			width: 100%;
			max-width: 740px;
			margin: 0 auto 50px auto;
		}
	}
	//.quarto-bloco

	.quinto-bloco{
		background-color: $amarelo;
		padding-top: 50px;
		.container{
			padding-bottom: 50px;
			border-bottom: 1px solid #CAB44D;
			position: relative;
			z-index: 2;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				background-image: url('../images/quote.png');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				z-index: -1;
			}
		}

		#carouselDepoimentos{
			.controles{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 80px;
				margin-top: 20px;
			}
		}

		.btn-padrao{
			margin: 40px auto 0 auto;
		}

		@include media-breakpoint-only(xl) {
			.container{
				&:after{
					width: 300px;
					height: 212px;
				}
			}
			#carouselDepoimentos{
				.controles{
					margin-left: 40px;
				}
			}
		}
		@include media-breakpoint-only(lg) {
			.container{
				&:after{
					width: 280px;
					height: 198px;
				}
			}
			#carouselDepoimentos{
				.controles{
					margin-left: 60px;
				}
			}
		}
		@include media-breakpoint-only(md) {
			.container{
				&:after{
					width: 250px;
					height: 177px;
				}
			}
			#carouselDepoimentos{
				.controles{
					margin-left: 30px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.container{
				&:after{
					width: 150px;
					height: 106px;
				}
			}
			#carouselDepoimentos{
				.controles{
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
	//.quinto-bloco
}
//.pag-quem-somos