.pag-cursos{
	.banner-topo{
		position: relative;
		z-index: 2;
		margin-bottom: 40px;
		.lazyimage{
			border-radius: 10px;
			overflow: hidden;
		}
	}
	//.banner-topo

	.box-categoria{
		.btn-header{
			display: flex;
			align-items: center;
			padding: 10px;
			height: 44px;
			font-size: 16px;
			line-height: 1;
			color: $azul;
			background-color: $amarelo;
			font-family: $trajan-bold;
			cursor: pointer;
			border-bottom: 1px solid #ccc;
		}
		#allCategorias{
			background-color: #eee;
			line-height: 1.2;

			.box + .box{
				border-top: 1px solid rgba(0,86,149,0.2);
			}

			.btn-cat{
				display: block;
				padding: 10px;
				cursor: pointer;
				color: #000;
				transition: all 0.3s linear;
				font-size: 15px;

				&:hover{
					background-color: #ccc;
				}
			}
			.btn-cat[aria-expanded="true"] {
				background-color: $amarelo;
			}

			.group-itens{
				background-color: #DFDFDF;

				a + a{
					border-top: 1px solid rgba(0,86,149,0.2);
				}

				a{
					display: block;
					padding: 10px;
					font-size: 15px;
					line-height: 1;
					font-size: 13px;
					color: #000;

					&:hover{
						background-color: #ccc;
					}
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.btn-header{
				pointer-events: none;
			}
			#allCategorias{
				display: block;
			}
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}
	//.box-categoria

	.card-blog{
		margin-top: 0;
		margin-bottom: 30px;
	}
	.paginacao{
		margin-top: 10px;
	}
}
//.pag-cursos