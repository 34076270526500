body{
	font-family: $nnt-regular;
}
body,
html{
	width:100%;
	min-height:100vh;
}
img[style]{
    max-width: 100%;
    height: auto !important;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1,h2,h3,h4,h5,h6,p{
	margin-bottom: 0;
}
a{
    color: inherit;
}
a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
}
*[data-animate]{
	opacity: 0;
}
.animated[data-animate]{
	opacity: 1;
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(#000, 0.6);
}

.redes{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.icone + .icone{
		margin-left: 10px;
	}

	.icone{
		display: block;
		width: 23px;
		height: 23px;
		background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;

		&.facebook{
			background-image: url('../images/facebook.png');
		}
		&.instagram{
			background-image: url('../images/instagram.png');
		}
		&.linkedin{
			background-image: url('../images/linkedin.png');
		}
	}
}
//redes

.title-1{
	position: relative;
	font-family: $trajan-regular;
	line-height: 1;
	color: #111;
	z-index: 1;
	text-align: center;

	&:before{
		content: '';
		position: absolute;
		z-index: -1;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		background-image: url('../images/icon-logo.png');
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	@include media-breakpoint-up(md) {
		font-size: 34px;
		padding-top: 70px;

		&:before{
			width: 349px;
			height: 250px;
		}
	}
	@include media-breakpoint-down(sm) {
		font-size: 22px;
		padding-top: 30px;

		&:before{
			width: 240px;
			height: 172px;
		}
	}
}

.desc{
	font-size: 16px;
	line-height: 1.4;
	color: #111;

	iframe,
	img{
		max-width: 100%;
		height: auto;
	}

	p + p{
		margin-top: 14px;
	}
}

.prev-carousel,
.next-carousel{
	width: 31px;
	height: 14px;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	background-size: 100% 100%;
}
.prev-carousel{
	background-image: url('../images/prev-carousel.png');
}
.next-carousel{
	background-image: url('../images/next-carousel.png');
}