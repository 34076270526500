.banners{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.carousel-indicators{
		right: auto;
		left: 0;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
		margin-bottom: 0;
		display: flex;
		flex-direction: column;

		li{
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background-color: #fff;
			opacity: 1;
			border: none;
			margin: 5px 0;
			transition: all 0.2s linear;

			&.active{
				background-color: $amarelo;
			}
		}

		@include media-breakpoint-only(xl) {
			margin-left: calc(calc(100% - 1170px)/2);
		}
		@include media-breakpoint-only(lg) {
			margin-left: calc(calc(100% - 940px)/2);
		}
		@include media-breakpoint-only(md) {
			margin-left: calc(calc(100% - 690px)/2);
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}

	}
}
//banners

.chamadas-pos-banner{
	background-color: $amarelo;

	.box-chamada{
		.texto{
			color: #212432;
			line-height: 1.2;
			font-size: 16px;

			span{
				font-family: $nnt-bold;
			}
		}
		.link{
			color: #212432;
			font-size: 13px;
			line-height: 1;
			display: inline-flex;
			align-items: center;
			font-family: $nnt-bold;
			transition: all 0.2s linear;

			i.fas{
				font-size: 24px;
				margin-left: 5px;
				margin-bottom: 3px;
			}

			&:hover{
				color: #d6ba39;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.box-chamada{
			display: flex;
			align-items: center;

			.img{
				margin-right: 10px;
			}
		}
	}
	@include media-breakpoint-between(md, lg) {
		.box-chamada{
			text-align: center;
			.img{
				margin-bottom: 5px;
				.lazyimage{
					margin: 0 auto;
				}
			}
			.link{
				justify-content: center;
			}
		}
	}
	@include media-breakpoint-up(md) {
		padding: 30px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 15px 0;
		.col-4{
			text-align: center;
		}
		.box-chamada{
			display: inline-block;
			text-align: center;
			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}

			.body{
				display: none;
			}
		}
	}
	// @include media-breakpoint-only(xs) {
	// 	.box-chamada{
	// 		text-align: center;
	// 		.img{
	// 			margin-bottom: 5px;
	// 			.lazyimage{
	// 				margin: 0 auto;
	// 			}
	// 		}
	// 		.link{
	// 			justify-content: center;
	// 		}
	// 	}
	// }
}
//.chamadas-pos-banner

.bem-vindo{
	padding-top: 30px;
	padding-bottom: 50px;

	.title-1,
	.sub-title,
	.desc{
		text-align: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.title-1{
		margin-bottom: 10px;
		max-width: 700px;
	}
	.sub-title{
		max-width: 700px;
		font-size: 17px;
		line-height: 1.5;
		color: #111;
	}
	.desc{
		max-width: 970px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.video-home{
		border-radius: 15px;
		overflow: hidden;
		margin-bottom: 50px;
	}
	@include media-breakpoint-up(lg) {
		.video-home{
			padding-bottom: percentage(410/1060);
		}
	}
	@include media-breakpoint-down(md) {
		.video-home{
			padding-bottom: percentage(700/1060);
		}
		.col-lg-4 +.col-lg-4{
			margin-top: 30px;
		}
	}

}
//.bem-vindo
