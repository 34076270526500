.card-chamadas{
	display: block;
	border-radius: 10px;
	box-shadow: 0 0 14px rgba(204,204,204,0.8);
	background-color: #fff;
	transition: all 0.3s linear;
	position: relative;
	z-index: 1;

	&:before{
		content: '';
		position: absolute;
		z-index: -1;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		background-image: url('../images/icon-logo.png');
		bottom: 1px;
		right: 10px;
		width: 192px;
		height: 138px;
		opacity: 0;
		transition: all 0.3s linear;
	}

	.img{
		margin-bottom: 10px;
	}
	.nome{
		text-transform: uppercase;
		font-family: $trajan-regular;
		font-size: 25px;
		line-height: 1;
		margin-bottom: 5px;
		transition: all 0.3s linear;
		color: $azul;
	}
	.texto{
		font-size: 16px;
		line-height: 1.4;
		margin-bottom: 5px;
		transition: all 0.3s linear;
		color: $azul;
	}
	.link{
		font-size: 13px;
		line-height: 1;
		display: inline-flex;
		align-items: center;
		font-family: $nnt-bold;
		transition: all 0.3s linear;
		color: $azul;

		i.fas{
			font-size: 24px;
			margin-left: 5px;
			margin-bottom: 3px;
			transition: all 0.3s linear;
			color: $azul;
		}
	}

	@include media-breakpoint-up(md) {
		padding: 30px 20px;
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 15px;
	}
	@include media-breakpoint-only(xs) {
		text-align: center;
		.img{
			.lazyimage{
				margin: 0 auto;
			}
		}
		.link{
			justify-content: center;
		}
	}

	&:hover{
		background-color: $azul;
		box-shadow: 0 0 0px rgba(204,204,204,0.8);

		&:before{
			opacity: 1;
		}

		.nome,
		.texto,
		.link,
		.link i.fas{
			color: $amarelo;
		}
	}
}
//.card-chamadas

.card-ultimos-posts{
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 260px;

	.img{
		margin-right: 10px;
		.lazyimage{
			border-radius: 5px;
			overflow: hidden;
		}
	}

	.nome{
		font-size: 15px;
		line-height: 1;
		margin-bottom: 2px;
		height: 30px;

		@include line-clamp(2);
	}
	.data{
		font-size: 13px;
		color: $amarelo;
		line-height: 1;
		text-transform: uppercase;
	}
}
//.card-ultimos-posts

.card-corpo-docente{
	color: #000;
	line-height: 1.3;
	font-size: 16px;

	.lazyimage{
		border-radius: 5px;
		overflow: hidden;
	}

	.nome{
		font-family: $trajan-bold;
		font-size: 25px;
		text-transform: uppercase;
	}
	.cargo{
		font-family: $trajan-regular;
		text-transform: uppercase;
		margin: 5px 0 10px 0;
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
		.lazyimage{
			margin: 0 auto 20px auto;
		}
	}
}
//.card-corpo-docente

.card-parceiros{
	display: block;
	width: 100%;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 16px;
	line-height: 1;
	color: #000;
	font-family: $nnt-bold;

	.img{
		margin-bottom: 10px;
	}

	.nome{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:hover{
		color: #000;
	}
}
//.card-parceiros

.card-depoimentos{
	color: #000;

	.lazyimage{
		border-radius: 50%;
		overflow: hidden;
	}

	.frase{
		font-size: 30px;
		line-height: 1;
		font-family: $nnt-bold;
		margin-bottom: 5px;
	}
	.nome{
		font-size: 15px;
		line-height: 1.2;
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
		.lazyimage{
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		.frase{
			font-size: 22px;
		}
	}
}
//.card-depoimentos

.card-blog{
	max-width: 270px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: center;

	.img{
		margin-bottom: 10px;
		.lazyimage{
			border-radius: 10px;
			overflow: hidden;
		}
	}

	.segura{
		padding-bottom: 10px;
		margin-bottom: 10px;
		position: relative;
		.titulo{
			font-family: $trajan-bold;
			font-size: 18px;
			line-height: 1.1;
			color: $azul;
			height: 38px;
			@include line-clamp(2);
		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 48px;
			height: 4px;
			background-color: $amarelo;
			border-radius: 2px;
		}
	}
	.texto{
		font-size: 15px;
		line-height: 1.1;
		color: #000;
		height: 64px;
		@include line-clamp(4);
	}
	.botao{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 42px;
		color: #fff !important;
		font-size: 13px;
		font-family: $nnt-bold;
		border-radius: 5px;
		background-color: $azul;
		transition: all 0.2s linear;
		margin-top: 10px;
		cursor: pointer;

		&:hover{
			color: $amarelo !important;
		}
	}
}
//.card-blog