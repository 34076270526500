.topo{

	ul{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.redes{
		justify-content: center;
	}
	//redes

	.campo-busca{
		.group{
			position: relative;

			.form-control{
				background-color: #eee;
				color: #2B2C3B;
				font-size: 14px;
				height: 42px;
				border: none;
				border-radius: 5px;

				&::placeholder{
					color: #2B2C3B;
				}
				&:focus{
					box-shadow: 0 0 0 0.2rem rgba(227, 215, 160, 0.41);	
				}
			}

			button{
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				background-color: #eee;
				border: none;
				outline: none;
				height: 90%;
				color: #000;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		background: rgb(255,255,255); /* Old browsers */
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(221,221,221,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(221,221,221,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(221,221,221,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */

		.main-navigation{
			width: 100%;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			padding-right: 15px;
			padding-left: 15px;
			display: flex;

			.logo{
				position: relative;
				z-index: 10;
				top: 10px;
				height: 90px;
			}

			.segura-content{
				flex-grow: 1;
				margin-left: 50px;

				.box-superior{
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 15px;
					padding-bottom: 12px;
					border-bottom: 1px solid #ddd;

					.campo-busca{
						.form-control{
							min-width: 290px;
						}
					}
					.midia{
						font-size: 14px;
						line-height: 1;
						color: #2B2D3C;

						a + a{
							margin-left: 5px;
							padding-left: 5px;
							border-left: 1px solid #2B2D3C;
						}

						i.fas{
							margin-right: 5px;
						}
					}
				}
				//.box-superior

				.box-inferior{
					.menu{
						display: flex;
						justify-content: space-between;
						li + li{
							position: relative;
							&:before{
								content: '';
								width: 1px;
								height: 28px;
								background-color: #bbb;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: -4px;
								transition: all 0.3s linear;
							}
						}
						li{
							display: flex;

							&.active a{
								background-color: $amarelo;
								font-weight: bold;
							}

							a{
								display: flex;
								align-items: center;
								font-size: 16px;
								padding: 18px 19px;

								&:hover{
									background-color: $amarelo;
									font-weight: bold;
								}
							}
						}
					}
				}
				//.box-inferior
			}
			//.segura-content
		}
		//main-navigation

		.mbl-controls,
		.bg-menu{
			display: none;
		}
	}
	//DESKTOP

	@include media-breakpoint-down(lg) {
		.main-navigation{
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			overflow: auto;
			z-index: 1000;
			background: #eee; /* Old browsers */
			background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(221,221,221,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */
			border-right: 1px solid #333;
			transition: left 0.4s ease-out;
			padding-bottom: 10px;

			&.active{
				left: 0;
			}

			.logo{
				display: none;
			}
			.segura-content{
				display: flex;
				flex-direction: column;

				.box-inferior{
					order: 1;
					.menu{
						position: relative;
						margin-bottom: 10px;

						&:before{
							content: 'MENU';
							position: relative;
							top: 0;
							left: 0;
							display: block;
							text-align: center;
							background-color: #2B2D3C;
							color: #fff;
							font-size: 20px;
							padding: 5px;
							font-family: $nnt-bold;
						}

						.active a{
							background-color: $amarelo;
							font-weight: bold;
						}
						a{
							display: block;
							padding: 5px;
							color: #000;
							font-size: 15px;

							&:hover{
								background-color: $amarelo;
								font-weight: bold;
							}
						}
					}
				}
				.box-superior{
					order: 2;
					.campo-busca{
						padding: 5px 10px;
						margin-bottom: 10px;
						.form-control{
							border: 1px solid #ddd;
						}
					}
					.midia{
						i.fas{
							margin-bottom: 5px;
						}
						text-align: center;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						line-height: 1.2;
						margin-bottom: 20px;
						color: #2B2D3C;
					}
				}
			}
		}
		//.main-navigation

		.mbl-controls{
			background: #eee; /* Old browsers */
			background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(221,221,221,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */
			
			.logo{
				width: 120px;
				position: relative;
				height: 90px;
				z-index: 10;
				top: 5px;
			}
			.container{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.botao-mbl{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				font-size: 24px;
				color: #2B2D3C;
				cursor: pointer;
				transition: all 0.3s linear;

				&.active{
					color: $amarelo;
				}
			}
		}
	}
	//DOWN-LG
}
//.topo