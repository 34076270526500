.internas{
	padding: 50px 0;

	.pag-header{
		text-align: center;
		.title-blog{
			font-size: 22px;
			margin-top: 15px;
			line-height: 1;
			font-family: $trajan-regular;
			color: #000;
		}
	}

	.btn-padrao.voltar{
		margin: 30px auto 0 auto;
	}

	@include media-breakpoint-up(md) {
		.title-1{
			&:before{
				width: 271px;
				height: 194px;
			}
		}
		.pag-header{
			margin-bottom: 60px;
		}
	}
	@include media-breakpoint-down(sm) {
		.title-1{
			&:before{
				width: 150px;
				height: 107px;
			}
		}
		.pag-header{
			margin-bottom: 50px;
		}
	}
}
//.internas

.bloco.form-estilos{
	margin-top: 40px;
	padding-top: 40px;
	border-top: 6px solid #eee;
	text-align: center;

	.title-2{
		margin-bottom: 30px;
		line-height: 1.1;
		color: $azul;
		font-family: $trajan-regular;

		@include media-breakpoint-up(md) {
			font-size: 34px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 22px;
		}
	}
}
.form-estilos{
	input.form-control{
		height: 44px;
	}
	.form-control{
		background-color: #eee;
		font-size: 16px;
		color: #000;
		border: none;
		border-radius: 0;

		&:focus{
			box-shadow: 0 0 0 0.2rem rgba(227, 215, 160, 0.41);	
		}
		&::placeholder{
			color: #000;
		}
	}
	.btn-padrao{
		margin: 30px auto 0 auto;
		outline: none;
	}
}
//form-estilos

.paginacao{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 40px;

	.active a{
		background-color: $azul;
		color: #fff;
	}

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border: 1px solid #ddd;
		font-size: 14px;
		color: #111;
		line-height: 1;

	}
}
//.paginacao