.modal{
	.modal-dialog{
		max-width: 800px;
		padding: 15px;
	}
	.modal-content{
		border-radius: 0;
		border: none;
		text-align: center;

		.modal-header{
			padding: 5px;
			border-bottom: none;

			button{
				color: #000 !important;
				outline: none;
			}
		}
		//.modal-header

		.titulo{
			color: $azul;
			line-height: 1.2;
			font-family: $trajan-regular;
			margin-bottom: 30px;
		}

		.form-estilos{
			.btn-padrao{
				margin-top: 15px;
			}
		}

		@include media-breakpoint-up(md) {
			.titulo{
				font-size: 34px;
			}
			.modal-body{
				padding: 50px;
			}
		}
		@include media-breakpoint-down(sm) {
			.titulo{
				font-size: 22px;
			}
		}
	}
	//.modal-content
}
//.modal

#modalConfirmacao{
	.titulo{
		position: relative;
		&:after{
			content: '';
			position: relative;
			display: block;
			width: 1px;
			height: 50px;
			background-color: #000;
			margin: 10px auto;
		}
	}

	iframe{
		max-width: 350px;
	}
}
//#modalConfirmacao