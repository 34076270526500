.rodape{

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.chamada-contato{
		background-color: $amarelo;
		color: $azul;
		padding: 20px 0;

		.titulo{
			font-family: $trajan-regular;
			line-height: 1.2;

			span{
				font-family: $trajan-bold;
			}
		}

		.botao{
			width: 290px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			background-color: $azul;
			border-radius: 4px;
			font-family: $nnt-bold;
			font-size: 13px;
			text-align: center;
			line-height: 1.2;
			transition: all 0.2s linear;

			&:hover{
				color: $amarelo;
			}
		}

		@include media-breakpoint-only(xl) {
			.titulo{
				font-size: 23px;
			}
		}
		@include media-breakpoint-only(lg) {
			.titulo{
				font-size: 20px;
			}
		}
		@include media-breakpoint-only(md) {
			.titulo{
				font-size: 16px;
			}
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			.botao{
				margin: 15px auto 0 auto;
			}
		}
	}
	//.chamada-contato

	.content{
		padding: 25px 0;
		background-color: $azul;
		color: #fff;
		font-size: 15px;
		line-height: 1.3;

		.logo{
			width: 90px;
			margin-bottom: 10px;
		}

		.midia{
			margin-top: 10px;

			i.fas{
				color: $amarelo;
			}
		}
		.title{
			color: $amarelo;
			font-family: $nnt-bold;
			font-size: 16px;
			margin-bottom: 10px;
		}
		.menu{
			font-size: 16px;

			.active a{
				font-family: $nnt-bold;
			}

			li + li{
				margin-top: 5px;
			}
		}

		.card-ultimos-posts + .card-ultimos-posts{
			margin-top: 10px;
		}

		.campo-newsletter{
			margin-top: 10px;
			margin-bottom: 10px;

			.group{
				position: relative;

				.form-control{
					background-color: #eee;
					font-size: 15px;
					color: $azul;
					border-radius: 6px;
					height: 42px;
					border: none;

					&:focus{
						box-shadow: none;
					}
					&::placeholder{
						color: $azul;
					}
				}

				button{
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					background-color: $amarelo;
					color: $azul;
					font-size: 12px;
					line-height: 1;
					font-family: $nnt-bold;
					border: none;
					border-radius: 0 6px 6px 0;
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.title{
				padding-top: 20px;
			}
		}
		@include media-breakpoint-between(md, lg) {
			.col-md-6:first-child,
			.col-md-6:nth-child(2){
				margin-bottom: 20px;
			}
		}
		@include media-breakpoint-up(sm) {
			.midia{
				display: flex;
				align-items: center;
				line-height: 1;

				a + a{
					margin-left: 5px;
					padding-left: 5px;
					border-left: 1px solid #fff;
				}

				i.fas{
					margin-right: 5px;
					min-width: 15px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			.card-ultimos-posts,
			.logo{
				margin-left: auto;
				margin-right: auto;
			}
			.midia,
			.redes{
				justify-content: center;
			}
			.card-ultimos-posts{
				text-align: left;
			}
			.col-md-6 + .col-md-6{
				margin-top: 15px;
			}
		}
		@include media-breakpoint-only(xs) {
			.midia{
				i.fas{
					margin-bottom: 5px;
				}
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}
	//.content

	.autorais{
		background-color: #1B1D27;
		color: #fff;
		font-size: 12px;
		line-height: 1;
		font-family: $lato-regular;
		padding: 10px 0;

		.container{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			text-align: center;

			p{
				margin: 5px;
			}

			a{
				display: inline-flex;
				align-items: center;
				justify-content: center;

				img{
					margin-left: 5px;
				}
			}
		}
	}
}
//.rodape