.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	width: 290px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: $azul;
	border-radius: 4px;
	font-family: $nnt-bold;
	font-size: 13px;
	text-align: center;
	line-height: 1.2;
	transition: all 0.2s linear;
	outline: none;
	border: none;

	&:hover{
		color: $amarelo;
	}
}