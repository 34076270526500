@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular/lato-regular-webfont.eot');
    src: url('../fonts/lato-regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular/lato-regular-webfont.ttf') format('truetype'),
         url('../fonts/lato-regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nunitobold';
    src: url('../fonts/nunito-bold/nunito-bold-webfont.eot');
    src: url('../fonts/nunito-bold/nunito-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/nunito-bold/nunito-bold-webfont.woff2') format('woff2'),
         url('../fonts/nunito-bold/nunito-bold-webfont.woff') format('woff'),
         url('../fonts/nunito-bold/nunito-bold-webfont.ttf') format('truetype'),
         url('../fonts/nunito-bold/nunito-bold-webfont.svg#nunitobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nunitoregular';
    src: url('../fonts/nunito-regular/nunito-regular-webfont.eot');
    src: url('../fonts/nunito-regular/nunito-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/nunito-regular/nunito-regular-webfont.woff2') format('woff2'),
         url('../fonts/nunito-regular/nunito-regular-webfont.woff') format('woff'),
         url('../fonts/nunito-regular/nunito-regular-webfont.ttf') format('truetype'),
         url('../fonts/nunito-regular/nunito-regular-webfont.svg#nunitoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'trajan-pro-bold';
    src: url('../fonts/TrajanPro-Bold/TrajanPro-Bold.eot');
    src: url('../fonts/TrajanPro-Bold/TrajanPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TrajanPro-Bold/TrajanPro-Bold.woff2') format('woff2'),
        url('../fonts/TrajanPro-Bold/TrajanPro-Bold.woff') format('woff'),
        url('../fonts/TrajanPro-Bold/TrajanPro-Bold.ttf') format('truetype'),
        url('../fonts/TrajanPro-Bold/TrajanPro-Bold.svg#TrajanPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'trajan-pro-regular';
    src: url('../fonts/TrajanPro-Regular/TrajanPro-Regular.eot');
    src: url('../fonts/TrajanPro-Regular/TrajanPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TrajanPro-Regular/TrajanPro-Regular.woff2') format('woff2'),
        url('../fonts/TrajanPro-Regular/TrajanPro-Regular.woff') format('woff'),
        url('../fonts/TrajanPro-Regular/TrajanPro-Regular.ttf') format('truetype'),
        url('../fonts/TrajanPro-Regular/TrajanPro-Regular.svg#TrajanPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}